import React, { useEffect, useState } from 'react'
import Menu from './Menu'
import { useNavigate } from 'react-router-dom'
import { Button, Modal } from 'react-bootstrap'
import Multiselect from 'multiselect-react-dropdown'

const LoanType = () => {
  const navigate = useNavigate()
  const [loantypes, setLoanTypes] = useState([])
  const [docs, setDocs] = useState([])
  const [visibleedit, setVisibleedit] = useState(false);
  const [visedit, setVisedit] = useState(false);
  const [selectedDoc, setselectedDoc] = useState([])
  const [formData, setformData] = useState({})
  const [formEditData, setformEditData] = useState({})
  const [editId,setId] = useState('')
  useEffect(() => {
    docsRequired()
    getLoanTypes()
  },[])
  const logout = () => {
    localStorage.clear('admintoken')
    navigate("/")
  }
  const docsRequired = () => {
    try {
      const reqOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      fetch(`https://phapi.handsintechnology.in/api/getAllDocuments`,reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            console.log(data.data, ">>>>> fdata")
            let docss = data.data
            const dt = docss.map((val)=>({_id:val._id,doc_name:val.doc_name}))
            setDocs(dt)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const handleFormChange = (val, name) => {
    setformData({...formData,[name]:val})
  }
  
  const submitForm = () => {
    try {
      const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body:JSON.stringify(formData)
      };
      fetch(`https://phapi.handsintechnology.in/api/addLoanType`, reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            getLoanTypes()
            setformData({})
            setVisibleedit(false)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const getLoanTypes = () => {
    try {
      const reqOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      fetch(`https://phapi.handsintechnology.in/api/getaddLoanType`, reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            console.log(data.data,">>>> loan data")
            setLoanTypes(data.data)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const EditLoanType = (id) => {
    try {
      setformData({})
      setId(id)
            setVisedit(true)
      const reqOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      fetch(`https://phapi.handsintechnology.in/api/getLoanTypebyid?id=${id}`, reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            console.log(data.data, ">>>> loan data")
            setformEditData(data.data)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const gotoAdd = () => {
    setformData({})
    setVisibleedit(false)
  }
  const submitEditForm = () => {
    try {
      const reqOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
      };
      fetch(`https://phapi.handsintechnology.in/api/updateLoanType?id=${editId}`, reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            getLoanTypes()
            setformData({})
            setVisedit(false)
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const ChangeStatus = (id, status) => {
    try {
      const reqOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },

      };
      fetch(`https://phapi.handsintechnology.in/api/updateLoanTypeStatus?id=${id}&status=${status}`, reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            getLoanTypes()
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const DeleteLoanType = (id) => {
    try {
      const reqOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },

      };
      fetch(`https://phapi.handsintechnology.in/api/DeleteLoanType?id=${id}`, reqOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            getLoanTypes()
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="content-wrapper">
      <Menu />
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Loan Type</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <div className="items-right">
                    <button
                      onClick={() => logout()}
                      className="btn btn-warning">
                      <i className="fa fa-sign-out" aria-hidden="true" />Logout
                    </button>
                  </div>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
              </ol>

            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <hr/>
      <div className="content">
        <div className="container-fluid">
          <div className='row'>
          </div>
          <div className="row">
            
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>No.</th>
                  <th>Loan Type Name</th>
                  <th>Loan Range</th>
                  <th>interest Rate</th>
                  <th>Processing Fee</th>
                  <th>Documents required</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loantypes?.length > 0 ?
                  <>

                    {loantypes?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.loantype}</td>
                        <td>{item.loan_amount_range}</td>
                        <td>{item.intrest_rate}</td>
                        <td>{item.processing_fee}</td>
                        <td>{item.documents?.map((item)=>item.doc_name)?.join(', ')}</td>
                        <td>
                          <button className='btn btn-info' onClick={() => EditLoanType(item._id)}>Edit</button>
                          <button className='btn btn-danger m-1' onClick={() => { if (window.confirm('Are you sure you wish to Delete this item?')) DeleteLoanType(item._id) }}>Delete</button>
                          {item.status ?
                            <button className='btn btn-warning' onClick={() => { if (window.confirm('Are you sure you wish to Deactivate this item?')) ChangeStatus(item._id, 2) }}>Deactivate</button>
                            :
                            <button className='btn btn-success' onClick={() => { if (window.confirm('Are you sure you wish to Activate this item?')) ChangeStatus(item._id, 1) }}>Activate</button>
                          }
                        </td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <Modal size='lg' show={visibleedit} onHide={() => gotoAdd()}>
        <Modal.Header >
          <Modal.Title>Add Loan Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label  className='form-label'>
                              <span style={{ color: '#e46e62' }}>Loan Type Name</span>
                            </label>
                            <input onChange={(e) => handleFormChange(e.target.value,e.target.name)} type="text" className='form-control'  name="loantype" />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label>
                            <span style={{ color: '#e46e62' }}>Loan Range</span>
                            </label>
                            <input onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="text" className='form-control'  name="loan_amount_range" />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label className='form-label'>
                            <span style={{ color: '#e46e62' }}>interest Rate</span>
                            </label>
                            <input onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="number" className='form-control'  name="intrest_rate" />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label className='form-label'>
                            <span style={{ color: '#e46e62' }}>Processing Fee</span>
                            </label>
                            <input onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="number" className='form-control'  name="processing_fee" />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label className='form-label'>
                            <span style={{ color: '#e46e62' }}>Documents requi#e46e62</span>
                            </label>
                            <Multiselect
                              options={docs}
                              selectedValues={selectedDoc}
                              displayValue="doc_name"
                              showCheckbox={true}
                              placeholder='Select documents required'
                              onSelect={(e) => handleFormChange(e, 'documents')}
                              onRemove={(e) => handleFormChange(e, 'documents')}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <button onClick={()=>submitForm()} className='btn btn-primary'>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => gotoAdd()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size='lg' show={visedit} onHide={() => setVisedit(false)}>
        <Modal.Header >
          <Modal.Title>Edit Loan Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label className='form-label'>
                              <span style={{ color: '#e46e62' }}>Loan Type Name</span>
                            </label>
                            <input defaultValue={formEditData.loantype} onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="text" className='form-control' name="loantype" />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            {/* <span style={{ color: '#e46e62' }}>Loan Range</span> */}
                            <label style={{ color: '#e46e62' }}>Loan Range</label>
                            <input defaultValue={formEditData.loan_amount_range} onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="text" className='form-control' name="loan_amount_range" />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            {/* <span style={{ color: '#e46e62' }}>interest Rate</span> */}
                            <label className='form-label' style={{ color: '#e46e62' }}>interest Rate</label>
                            <input defaultValue={formEditData.intrest_rate} onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="number" className='form-control' name="intrest_rate" />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            {/* <span style={{ color: '#e46e62' }}>Processing Fee</span> */}
                            <label className='form-label' style={{ color: '#e46e62' }}>Processing Fee</label>
                            <input defaultValue={formEditData.processing_fee} onChange={(e) => handleFormChange(e.target.value, e.target.name)} type="number" className='form-control' name="processing_fee" />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            {/* <span style={{ color: '#e46e62' }}>Documents requi#e46e62</span> */}
                            <label className='form-label' style={{ color: '#e46e62' }}>Documents required</label>
                            <Multiselect
                              options={docs}
                              selectedValues={formEditData.documents}
                              displayValue="doc_name"
                              showCheckbox={true}
                              placeholder='Select documents required'
                              onSelect={(e) => handleFormChange(e, 'documents')}
                              onRemove={(e) => handleFormChange(e, 'documents')}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    <button onClick={() => submitEditForm()} className='btn btn-primary'>Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setVisedit(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default LoanType
