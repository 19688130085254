import React, { useEffect, useState } from 'react'
import Menu from './Menu'
import { useNavigate } from 'react-router-dom'

const Documents = () => {
    const navigate = useNavigate()
    const [docData,setDocData] = useState([])
    useEffect(() => {
        getdocData()
    }, [])
    
    const getdocData = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`https://phapi.handsintechnology.in/api/getAllDocuments`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                // console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
                setDocData(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const logout = () => {
        localStorage.clear('admintoken')
        navigate("/")
    }
  return (
      <div className="content-wrapper">
          <Menu />
          <div className="content-header">
              <div className="container-fluid">
                  <div className="row mb-2">
                      <div className="col-sm-6">
                          <h1 className="m-0 text-dark">Documents</h1>
                      </div>
                      {/* /.col */}
                      <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-right">
                              <li className="breadcrumb-item">
                                  <div className="items-right">
                                      <button
                                          onClick={() => logout()}
                                          className="btn btn-warning">
                                          <i className="fa fa-sign-out" aria-hidden="true" />Logout
                                      </button>
                                  </div>
                              </li>
                              {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
                          </ol>

                      </div>
                      {/* /.col */}
                  </div>
                  {/* /.row */}
              </div>
              {/* /.container-fluid */}
          </div>
    </div>
  )
}

export default Documents
