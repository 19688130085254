import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/logo.svg"
import { Dropdown, NavItem } from "react-bootstrap";
export default function Menu() {
  const [adminDetails, setAdminDetails] = useState([]);
  useEffect(() => {
    // const id = localStorage.getItem('adminid')
    // GetAdminDetails(id)
  }, []);
  const GetAdminDetails = async (id) => {
    // const requestOptions = {
    //   method: 'GET',
    //   headers: { 'Content-Type': 'application/json' },
    // };
    // const response = await fetch(`https://phapi.handsintechnology.in/api/get_admin_by_id?id=${id}`, requestOptions)
    // const res = await response.json()
    // console.log(res, "res")
    // if (res.status === 200) {
    //   const reponse = res.data
    //   setAdminDetails(reponse)
    // }

  }

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <Link to="/" className="brand-link">
        <img
          src={'https://powerhouse.handsintechnology.in/images/Option.png'}
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: "1" }}
        />
      </Link>
      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          {/* <div className="image">
            <img
              src={``}
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div> */}
          <div className="info">
            <a href="#" className="d-block">
              {adminDetails?.first_name} {adminDetails?.last_name}
            </a>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar nav-legacy flex-column" data-widget="treeview" role="menu">
            <li className="nav-header">NAVIGATIONS</li>
            <Link to="/Verifications" className="nav-link">
            <li className="nav-item ">
                <i className="fas fa-user" />&nbsp; <p>KYC Verifications</p>
            </li>
              </Link>
            <Link to="/LoanApplications" className="nav-link">
            <li className="nav-item active">
                <i className="fas fa-book" />&nbsp; <p>Loan Applications</p>
            </li>
              </Link>
            {/* <Link to="/DescisionMaking" className="nav-link">
            <li className="nav-item ">
                  <i className="fas fa-user" />&nbsp; <p>Decision Making</p>
            </li>
              </Link> */}
            <Link to="/LoanDisburstment" className="nav-link">
            <li className="nav-item">

                <i className="fas fa-hand-holding-medical" />&nbsp; <p>Loan Disbursement</p>
            </li>
            </Link>
            <Link to="/LoanManagement" className="nav-link">
              <li className="nav-item">
  
                  <i className="fas fa-flag" />&nbsp; <p>Loan Management</p>
              </li>
            </Link>
            <Link to="/DuedDashboard" className="nav-link">
              <li className="nav-item">
                <i className="far fa-calendar-check" />&nbsp; <p>Due Dashboard</p>
              </li>
            </Link>
            {/* <Link to="/loanManagement" className="nav-link">
              <li className="nav-item">
                <a className="nav-link ">
                  <i className="fas fa-flag" />&nbsp; <p>All Loans</p></a>
              </li>
            </Link> */}
            <Link to="/Testimonials" className="nav-link">
              <li className="nav-item">
                <i className="far fa-calendar-check" />&nbsp; <p>Testimonials</p>
              </li>
            </Link>
            <Link to="/LoanType" className="nav-link">
              <li className="nav-item">
                <i className="far fa-calendar-check" />&nbsp; <p>Loan Type</p>
              </li>
            </Link>
                    {/* <i className="far fa-setting" />&nbsp; <p>Masters</p>
                <ul>
                  <li className="nav-item">
                    Loan Type
                  </li>
                  <li className="nav-item">
                    Testimonials
                  </li>
                  <li className="nav-item">
                    Loan Type
                  </li>
                </ul> */}
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
}
