import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "../src/App.css";
import Login from "./components/login/Login";
import Myroutes from "./routes/Myroutes";
export default function App() {
  return (
    <div>
      <Myroutes />
    </div>
  );
}
