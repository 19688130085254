import React, { useEffect, useState } from 'react'
import Menu from '../components/master/Menu'
import Header from '../components/master/Header'
import { Tab, Tabs } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const LoanManagement = () => {
  const navigate = useNavigate()
  const [disburstitems, setDisburstitem] = useState([])
  const [rejecteditems, setRejecteditem] = useState([])
  const [settleditems, setSettleditems] = useState([])
  const [load,setLOad] = useState('')
  
  useEffect(() => {
    const token = localStorage.getItem('admintoken')
    if (token) {
    GetDisburst()
    GetRejected()
    GetSettled()
    } else {
      navigate("/")
    }
  }, [load])
  const GetDisburst = () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      let status = 'disburst'
      fetch(`https://phapi.handsintechnology.in/api/Allloans?status=${status}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            // console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
            setDisburstitem(data.data)
            console.log(data.data," disbutst loans")
          }
        })
      
    } catch (error) {
      console.log(error)
    }
  }
  
  const GetRejected = () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      let status = 'rejected'
      fetch(`https://phapi.handsintechnology.in/api/Allloans?status=${status}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setRejecteditem(data.data)
            console.log(data.data, " rejected loans")

          }
        })

    } catch (error) {
      console.log(error)
    }
  }
  const GetSettled = () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      let status = 'settled'
      fetch(`https://phapi.handsintechnology.in/api/Allloans?status=${status}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setSettleditems(data.data)
            console.log(data.data, " settled loans")
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  
  const deleteLoan = () => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      };

      fetch(`https://phapi.handsintechnology.in/api/deleteLoan`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            setSettleditems(data.data)
            console.log(data.data, " settled loans")
          }
        })
    } catch (error) {
      console.log(error)
    }
  }
  const logout = () => {
    localStorage.clear('admintoken')
    navigate("/")
  }
  return (
    <div className="content-wrapper">
      {/* <Header/> */}
      
          <Menu/>
      <div
        // style={{ background: '#ebceba' }}
        className=" content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Loan Management</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <div className="items-right">
                    <button
                      onClick={() => logout()}
                      className="btn btn-warning">
                      <i className="fa fa-sign-out" aria-hidden="true" />Logout
                    </button>
                  </div>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
              </ol>

            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <Tabs
        onClick={()=>setLOad("hii")}
          defaultActiveKey="home"
          id="fill-tab-example"   
          className="mb-2"
        >
        <Tab eventKey="home" title="Disbursed Loans">
            {/* <Disbursed /> */}
          <div className="row">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>No.</th>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Loan Amount</th>
                  <th>EMI's</th>
                  <th>Intrest</th>
                  <th>Issued Date</th>
                  <th>Due Date</th>
                  <th>Last Due Date</th>
                </tr>
              </thead>
              <tbody>
                {disburstitems?.length > 0 ?
                  <>

                    {disburstitems?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.applicant[0].first_name} {item.applicant[0].last_name}</td>
                        <td>{item.applicant[0].phone}</td>
                        <td>{item.applicant[0].email}</td>
                        <td>{item.amtReq}</td>
                        <td>{item.totalEMIs}</td>
                        <td>{item.interest}</td>
                        <td>{item.loanIssuedDate?.slice(0, 10)}</td>
                        <td>{item.dateDue?.slice(0, 10)}</td>
                        <td>{item?.lasetDueDate?.slice(0, 10)}</td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
          </div>
          </Tab>
          <Tab eventKey="contact" title="Settled Loans">
          <div className="row">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>No.</th>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>penalty</th>
                  <th>Loan Amount</th>
                </tr>
              </thead>
              <tbody>
                {settleditems?.length > 0 ?
                  <>

                    {settleditems?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.applicant[0].first_name} {item.applicant[0].last_name}</td>
                        <td>{item.applicant[0].phone}</td>
                        <td>{item.applicant[0].email}</td>
                        <td> {item?.penalty?item.penalty:0}</td>
                        <td> {item.amtReq}</td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
          </div>
          </Tab>
          <Tab eventKey="profile" title="Rejected Loans">
          <div className="row">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>No.</th>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Loan Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rejecteditems?.length > 0 ?
                  <>

                    {rejecteditems?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.applicant[0].first_name} {item.applicant[0].last_name}</td>
                        <td>{item.applicant[0].phone}</td>
                        <td>{item.applicant[0].email}</td>
                        <td>
                          {item.amtReq}
                          {/* <button className="btn btn-primary" onClick={() => { usersDetails(item._id); }}>Edit</button> */}

                                                    {' '}
                        </td>
                        <td>
                          <button className="btn btn-danger mx-1" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteLoan(item._id) }}>Delete</button>
                        </td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
          </div>
          </Tab>
        </Tabs>
    

    </div>
  )
}

export default LoanManagement
