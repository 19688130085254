import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Menu from '../components/master/Menu'
  import { Modal, Button } from 'react-bootstrap';
import Headers from '../components/master/Header'
const LoanDisburstment = () => {
  const navigate = useNavigate()
    const [users, setusersData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [visibleedit, setVisibleedit] = useState(false);
  const [visibladd, setVisibleAdd] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('admintoken')
    if (token) {
      GetusersData();
    } else {
      navigate("/")
    }
  }, []);
  const GetusersData = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      let status = 'approved'
      const response = await fetch(`https://phapi.handsintechnology.in/api/Allloans?status=${status}`, requestOptions)
      const res = await response.json()
      if (res.status == 200) {
        // console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
        setusersData(res?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const DisburstLoan = (item,status) => {
    try {
      // return false
      const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
          recepient: item.recepient,
          amtReq: item.amtReq,
          totalEMIs: item.totalEMIs
        }),
        headers: { 'Content-Type': 'application/json' },
      };
      fetch(`https://phapi.handsintechnology.in/api/issueLoan?status=${status}&id=${item._id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 200) {
            console.log(data?.data, ">>>>>>>>>>>>>>>> resdata")
            setusersData(data?.data)
          }
        })

     
    } catch (error) {
      console.log(error)
    }
  }
  const logout = () => {
    localStorage.clear('admintoken')
    navigate("/")
  }
  return (
    <div className="content-wrapper">
          {/* <Headers /> */}
          <Menu />
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Loan Disbursement</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <div className="items-right">
                    <button
                      onClick={() => logout()}
                      className="btn btn-warning">
                      <i className="fa fa-sign-out" aria-hidden="true" />Logout
                    </button>
                  </div>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
              </ol>

            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <hr/>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>No.</th>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>KYC Status</th>
                  <th>Monthly Turnover</th>
                  <th>EMI's</th>
                  <th>Loan Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users?.length > 0 ?
                  <>

                    {users?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.applicant[0]?.first_name} {item.applicant[0]?.last_name}</td>
                        <td>{item.applicant[0]?.phone}</td>
                        <td>{item.applicant[0]?.email}</td>
                        <td>{item.applicant[0]?.kycstatus}</td>
                        <td>{item.applicant[0]?.monthly_turnover}</td>
                        <td>{item.totalEMIs }</td>
                        <td>
                          {item.amtReq}
                        </td>
                        <td>
                          {/* <button className='btn btn-primary'>View</button> */}
                          <button className='btn btn-success m-1' onClick={() => { if (window.confirm('Are you sure you wish to Disburst this Loan?')) DisburstLoan(item, 'disburst') }}>Disburse</button>
                          <button className='btn btn-danger m-1' onClick={() => { if (window.confirm('Are you sure you wish to Reject this item?')) DisburstLoan(item, 'rejected') }}>Reject</button>
                     
                        </td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>


    </div>
  )
}

export default LoanDisburstment
