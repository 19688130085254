import React, { useEffect, useState } from "react";
import Menu from '../components/master/Menu'
import { useNavigate } from "react-router-dom";

const Testimonials = () => {
    const navigate = useNavigate()
    const [testimonials, setTestimonials] = useState([]);
    useEffect(() => {
        GetusersData()
    }, [])
    const GetusersData = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`https://phapi.handsintechnology.in/api/getalltestimonials`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
                setTestimonials(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const deleteTestimonial = async (id) => {
        try {
            const requestOptions = {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`https://phapi.handsintechnology.in/api/deletetestimonial?id=${id}`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
                // setTestimonials(res?.data)
                GetusersData()
            }
        } catch (error) {
            console.log(error)
        }
    }
    const ChangeStatus = async (id, status) => {
        
        try {
            // alert(status)
            // return false;
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`https://phapi.handsintechnology.in/api/chageTestimonialstatus?id=${id}&status=${status}`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
                // setTestimonials(res?.data)
                GetusersData()
            }
        } catch (error) {
            console.log(error)
        }
    }
    const logout = () => {
        localStorage.clear('admintoken')
        navigate("/")
    }
  return (
      <div className="content-wrapper">
          <Menu />
          <div className="content-header">
              <div className="container-fluid">
                  <div className="row mb-2">
                      <div className="col-sm-6">
                          <h1 className="m-0 text-dark">Testimonials</h1>
                      </div>
                      {/* /.col */}
                      <div className="col-sm-6">
                          <ol className="breadcrumb float-sm-right">
                              <li className="breadcrumb-item">
                                  <div className="items-right">
                                      <button
                                          onClick={() => logout()}
                                          className="btn btn-warning">
                                          <i className="fa fa-sign-out" aria-hidden="true" />Logout
                                      </button>
                                  </div>
                              </li>
                              {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
                          </ol>

                      </div>
                      {/* /.col */}
                  </div>
                  {/* /.row */}
              </div>
              {/* /.container-fluid */}
          </div>
          <hr/>
          <div className="content">
              <div className="container-fluid">
                  <div className="row">
                      <table className="table table-bordered">
                          <thead className="thead-dark">
                              <tr className="table-info">
                                  <th> No.</th>
                                  <th>Full Name</th>
                                  <th>Phone</th>
                                  <th>Email</th>
                                  <th>Message</th>
                                  <th>Ratings</th>
                                  <th>Action</th>
                              </tr>
                          </thead>
                          <tbody>
                              {testimonials?.length > 0 ?
                                  <>

                                      {testimonials?.map((item, index) => (
                                          <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{item.applicant[0].first_name} {item.applicant[0].last_name}</td>
                                              <td>{item.applicant[0].phone}</td>
                                              <td>{item.applicant[0].email}</td>
                                              <td>{item.message}</td>
                                              <td>{item.ratings}</td>
                                              <td>
                                                  <button className="btn btn-primary"
                                                      onClick={() => { if (window.confirm('Confirm to Delete this Testimonial')) deleteTestimonial(item?._id) }}
                                                  >Delete</button>
                                                  {item.status  ?
                                                       <button className="btn btn-danger mx-1"
                                                          onClick={() => { if (window.confirm('Confirm to Activate this Testimonial')) ChangeStatus(item._id, 2) }}
                                                  >Deactivate</button>
                                                  :
                                                      
                                                      <button className="btn btn-success mx-1 my-1"
                                                          onClick={() => {
                                                              if (window.confirm('Confirm to Deactivate this Testimonial')) ChangeStatus(item._id, 1)
                                                          }}
                                                      >Activate</button> 
                                                  }
                                              </td>
                                          </tr>
                                      ))}

                                  </>
                                  :
                                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                              }

                          </tbody>
                      </table>
                  </div>
                  {/* /.row */}
              </div>
              {/* /.container-fluid */}
          </div>
    </div>
  )
}

export default Testimonials
