import React, { useEffect, useState } from 'react'
import { json, useNavigate } from 'react-router-dom'


const Login = () => {
const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit =  (e) => {
    e.preventDefault()
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        username: username,
        password:password
      })
    };
    fetch('https://phapi.handsintechnology.in/api/adminlogin', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          const Data = data.data

          let obj = {
            name: Data[0].name,
            token:Data[0]._id
          }
          
          localStorage.setItem('admintoken', JSON.stringify(obj));
          navigate("/LoanApplications")
        } else if(data.status =='400') {
          alert(data.message)
        }
      })


 
}
  return (
    <div className="login-page">
      <div className="login-box">
        {/* /.login-logo */}
        <div className="card">
          <div className="card-body login-card-body">
            <div className="login-logo">
              <img src={'https://powerhouse.handsintechnology.in/images/Option.png'} style={{height:'150px',width:'250px'}} />
            </div>
            {/* <p className="login-box-msg">Sign in</p> */}
            <form className="form">
              <div className="input-group mb-3">
                <input
                  type="username"
                  name='username'
                  style={{ backgroundColor:'#eed7c6'}}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                  placeholder="Username"
                  
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  type="password"
                  name='password'
                  style={{ backgroundColor: '#eed7c6' }}
                  onChange={(e)=>setPassword(e.target.value)}
                  className="form-control"
                  placeholder="Password"
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div className="row">
                {/* /.col */}
                <div className="col-12 mb-2">
                  <button style={{ background: '#dc5a4d', color:'#eed7c6',fontWeight:'600'}} onClick={(e)=>handleSubmit(e)} className="btn btn btn-block">
                    Log In
                  </button>
                </div>
                {/* /.col */}
              </div>
            </form>
            {/* /.social-auth-links */}
            <p  className="mb-1">
              <a style={{ color: '#913029',fontWeight:'500' }} href="forgot-password.html">I forgot my password</a>
            </p>
          </div>
          {/* /.login-card-body */}
        </div>
      </div>
    </div>
  )
}

export default Login
