import React, { useEffect, useState } from 'react'
import Menu from '../components/master/Menu'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const DueDashboard = () => {
  const navigate = useNavigate()
  const [todaysDue, setTodaysDue] = useState([])
  const [weeklyDue, setWeeklyDue] = useState([])
  const [alldues, setalldues] = useState([])
  const [formData, setFormData] = useState({})
  const [visibleedit, setVisibleedit] = useState(false);
  const [historyModal, setHistoryModal] = useState(false)
  const [historyData, setHistoryData] = useState([])
  const [buisnessTypeList, setBTypeList] = useState([])
  useEffect(() => {
    getTodaysDue()
    getWeekly()
    getAll()
    getBusinessTypeList()
  }, [])
  const getTodaysDue =async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(`https://phapi.handsintechnology.in/api/getDues?due=today `, requestOptions)
      const res = await response.json()
      if (res.status == 200) {
        setTodaysDue(res.data)
        console.log(res?.data, ">>>>>>>>>>>>>>>> todays due")

      }
    } catch (error) {
      console.log(error)
    }
  }
  const getWeekly = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(`https://phapi.handsintechnology.in/api/getDues?due=week`, requestOptions)
      const res = await response.json()
      if (res.status == 200) {
        setWeeklyDue(res.data)
        console.log(res?.data, ">>>>>>>>>>>>>>>> weekly due")

      }
    } catch (error) {
      console.log(error)
    }
  }
  const getAll = async () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const response = await fetch(`https://phapi.handsintechnology.in/api/getDues?due=all`, requestOptions)
      const res = await response.json()
      if (res.status == 200) {
        setalldues(res.data)
        console.log(res?.data, ">>>>>>>>>>>>>>>> all due")

      }
    } catch (error) {
      console.log(error)
    }
  }
  const ViewDetails = async (id) => {
    try {
      setVisibleedit(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetch(`https://phapi.handsintechnology.in/api/getUserDetails?id=${id}`, requestOptions)
      const res = await response.json()
      if (res.status == 200) {
        console.log(res?.data, ">>>>>>>>>>>>>>>> user dataaa")
        setFormData(res?.data[0])

      }
    } catch (error) {
      console.log(error)
    }
  }
  const logout = () => {
    localStorage.clear('admintoken')
    navigate("/")
  }
  const paymentDetails =async (userid, loanid) => {
    try {
      setHistoryModal(true)
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      const response = await fetch(`https://phapi.handsintechnology.in/api/getPaymentHistory?userid=${userid}&loanid=${loanid}`, requestOptions)
      const res = await response.json()
      if (res.status == 200) {
        console.log(res?.data, ">>>>>>>>>>>>>>>> history dataaa")
        setHistoryData(res?.data)

      }
    } catch (error) {
      console.log(error)
    }
  }
  const getBusinessTypeList = () => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      fetch(`https://phapi.handsintechnology.in/api/businessTypeList`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          if (res.status == 200) {
            console.log(res.data, ">>>>>buisness type list data")
            setBTypeList(res?.data)
          }
          // 
        })

    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className="content-wrapper">
      <Menu />
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 text-dark">Due Dashboard</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <div className="items-right">
                    <button
                      onClick={() => logout()}
                      className="btn btn-warning">
                      <i className="fa fa-sign-out" aria-hidden="true" />Logout
                    </button>
                  </div>
                </li>
                {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
              </ol>

            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      <hr/>
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <table className="table table-bordered">
              <thead className="thead-dark">
                <tr className="table-info">
                  <th>No.</th>
                  <th>Due Date</th>
                  <th>Full Name</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Loan</th>
                  <th>Balance</th>
                  <th>total EMIs</th>
                  <th>EMIs Pending</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {alldues?.length > 0 ?
                  <>

                    {alldues?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.dateDue?.slice(0,10)}</td>
                        <td>{item.user.first_name} {item.user.last_name}</td>
                        <td>{item.user.phone}</td>
                        <td>{item.user.email}</td>
                        <td>{item.amtReq}</td>
                        <td>{item.balence}</td>
                        <td>{item.emi}</td>
                        <td>{item.totalEMIs}</td>
                        <td>
                          <button className='btn btn-success' onClick={() => paymentDetails(item.user?._id,item._id)}>Payment Details</button>
                          <button className='btn btn-primary m-1' onClick={() => ViewDetails(item.user?._id)}>View Details</button>
                        </td>
                      </tr>
                    ))}

                  </>
                  :
                  <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                }

              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal size='lg' show={visibleedit} onHide={() => setVisibleedit(false)}>
        <Modal.Header >
          <Modal.Title>Application Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="FirstName" className='form-label'>
                              <span style={{ color: '#e46e62' }}>First name</span>
                            </label>
                            <input type="text" className='form-control' id="FirstName" name="FirstName" value={formData.first_name} />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="LastName" className='form-label'>
                            <span style={{ color: '#e46e62' }}>Last Name</span>
                            </label>
                            <input type="text" className='form-control' id="LastName" name="LastName" value={formData.last_name}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="Email" className='form-label'>
                            <span style={{ color: '#e46e62' }}>Email</span>
                            </label>
                            <input type="email" className='form-control' id="Email" name="email" value={formData.email} />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="Phone" className='form-label'>
                            <span style={{ color: '#e46e62' }}>Phone</span>
                            </label>
                            <input type='tel' className='form-control' id="Phone" name="Phone" value={formData.phone} />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="LoansTaken" className='form-label'>
                            <span style={{ color: '#e46e62' }}>Loans Taken</span>
                            </label>
                            <input type="number" className='form-control' id="LoansTaken" name="LoansTaken" value={formData?.loansTaken?.length} />
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="KYCStatus" className='form-label'>
                            <span style={{ color: '#e46e62' }}>KYC Status</span>
                            </label>
                            <input type="text" className='form-control' id="KYCStatus" name="KYCStatus" value={formData.kycstatus} />
                          </div>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <label htmlFor="BusinessType" className='form-label'>
                              <span style={{ color: '#e46e62' }}>Buisness Type</span>
                            </label>
                            <select className='form-control'>
                              <option hidden>Select Buisness Type</option>
                              {buisnessTypeList?.map((item, indx) =>
                                <option selected={formData.buisness_type == item._id} key={indx}>{item.buisness_type_name}</option>)
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                      <label htmlFor="KYCDetails" className='form-label'>
                      <span style={{ color: '#e46e62' }}>KYC Details</span>
                      </label>
                      <div className='row'>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <span style={{ color: 'black' }}>Pan Number : {formData?.kyc?.panno}</span>
                            <label htmlFor="BusinessType" className='form-label'></label>
                            <div style={{ height: '250px', width: '250px' }}>
                              <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/PanCard/${formData?.kyc?.panImage}`} alt='image not found' />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <span style={{ color: 'black' }}>Aadhar Number : {formData?.kyc?.adhaarno}</span>
                            <label htmlFor="BusinessType" className='form-label'></label>
                            <div style={{ height: '250px', width: '250px' }}>
                              <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/Aadhar/${formData?.kyc?.adhaarImage}`} alt='image not found' />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <span style={{ color: 'black' }}>Bank Statement </span>
                            <label htmlFor="BusinessType" className='form-label'></label>
                            <div style={{ height: '250px', width: '250px' }}>
                              <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/BankStatement/${formData?.kyc?.bankstatement}`} alt='image not found' />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <span style={{ color: 'black' }}>GST No. : {formData?.kyc?.GSTno}</span>
                            <label htmlFor="BusinessType" className='form-label'></label>
                            <div style={{ height: '250px', width: '250px' }}>
                              <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/GST/${formData?.kyc?.GSTdoc}`} alt='image not found' />
                            </div>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='mb-3'>
                            <span style={{ color: 'black' }}>MSME </span>
                            <label htmlFor="BusinessType" className='form-label'></label>
                            <div style={{ height: '250px', width: '250px' }}>
                              <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/MSME/${formData?.kyc?.MSME}`} alt='image not found' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setVisibleedit(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal size='lg' show={historyModal} onHide={() => setHistoryModal(false)}>
        <Modal.Header  closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                      <h6>Total EMIs : {historyData[0]?.loan?.emi}</h6>
                      <h6>Balance : {historyData[0]?.loan?.balence}</h6>
                    <div className='row'>
                      
                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr className="table-info">
                            <th>No.</th>
                            <th>Payment Date</th>
                            <th>EM'I No.</th>
                            <th>Amount Paid</th>
    
                          </tr>
                        </thead>
                        <tbody>
                          {historyData?.length > 0 ?
                            <>

                              {historyData?.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.createdAt?.slice(0, 10)}</td>
                                  {/* <td>{item.applicant.first_name} {item.applicant.last_name}</td> */}
                                  <td>{item.emi_no}</td>
                                  <td>{item.amount_paid}</td>
                                  {/* <td>{item.amtReq}</td> */}
                                  {/* <td>{item.totalEMIs}</td> */}
                                  {/* <td>
                                    <button className='btn btn-success' onClick={() => ViewDetails(item.applicant?._id)}>Payment Details</button>
                                    <button className='btn btn-primary m-1' onClick={() => ViewDetails(item.applicant?._id)}>View Details</button>
                                  </td> */}
                                </tr>
                              ))}

                            </>
                            :
                            <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                          }

                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setHistoryModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DueDashboard
