import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';

const ApprovedKYC = () => {
    const [approved, setApproved] = useState([])
    const [perPage] = useState(10);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [visibleedit, setVisibleedit] = useState(false);
    const [formData, setFormData] = useState({})
    const [buisnessTypeList,setBTypeList] = useState([])
    useEffect(() => {
        getApproved(page, perPage)
        getBusinessTypeList()
    }, [])
    const startFrom = (page - 1) * perPage;
    const handlePageClick = (e) => {
        const selectedPage = e.selected;
        setPage(selectedPage + 1);
        getApproved(selectedPage + 1, perPage);
    };
    const ViewDetails = async (id) => {
        try {
            setVisibleedit(true)
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = await fetch(`https://phapi.handsintechnology.in/api/getUserDetails?id=${id}`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                console.log(res?.data, ">>>>>>>>>>>>>>>> user dataaa")
                setFormData(res?.data[0])

            }
        } catch (error) {
            console.log(error)
        }
    }
    const getApproved = (page,limit) => {
        try {
            let status = 'approved'
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            fetch(`https://phapi.handsintechnology.in/api/approvedRejectedkyc?status=${status}&page=${page}&limit=${limit}`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                    if (res.status == 200) {
                        console.log(res.data, ">>>>>approved res data")
                        setApproved(res?.data)
                    }
                    // getBusinessTypeList
                })

        } catch (error) {
            console.log(error)
        }
    }
    const getBusinessTypeList = () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            fetch(`https://phapi.handsintechnology.in/api/businessTypeList`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                    if (res.status == 200) {
                        console.log(res.data, ">>>>>buisness type list data")
                        setBTypeList(res?.data)
                    }
                    // 
                })

        } catch (error) {
            console.log(error)
        }
    }
    return (
      <div>
      <div className="content">
          <div className="container-fluid">
              <div className="row">
                  <table className="table table-bordered">
                      <thead className="thead-dark">
                          <tr className="table-info">
                              <th>No.</th>
                              <th>Full Name</th>
                              <th>Phone</th>
                              <th>Email</th>
                              <th>Eligibility</th>
                              <th>Action</th>
                          </tr>
                      </thead>
                      <tbody>
                          {approved?.length > 0 ?
                              <>

                                  {approved?.map((item, index) => (
                                      <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item.user.first_name} {item.user.last_name}</td>
                                          <td>{item.user?.phone}</td>
                                          <td>{item.user?.email}</td>
                                          {/* <td>{item.user?.email}</td> */}
                                          {/* <td>{ item.user?.monthly_turnover}</td> */}
                                          <td>{item.user?.eligibility}</td>
                                          <td>
                                              <button className='btn btn-primary' onClick={() => ViewDetails(item.user?._id)}>View Details</button>
                                          </td>
                                      </tr>
                                  ))}

                              </>
                              :
                              <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                          }

                      </tbody>
                        </table>
                        {/* <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination justify-content-end"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                        /> */}
              </div>
          </div>
        </div>
            <Modal size='lg' show={visibleedit} onHide={() => setVisibleedit(false)}>
                <Modal.Header >
                    <Modal.Title>Application Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="FirstName" className='form-label'>
                                                            <span style={{ color: '#e46e62' }}>First name</span>
                                                        </label>
                                                        <input type="text" className='form-control' id="FirstName" name="FirstName" value={formData.first_name} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="LastName" className='form-label'>
                                                        <span style={{ color: '#e46e62' }}>Last Name</span>
                                                        </label>
                                                        <input type="text" className='form-control' id="LastName" name="LastName" value={formData.last_name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="Email" className='form-label'>
                                                        <span style={{ color: '#e46e62' }}>Email</span> 
                                                        </label>
                                                        <input type="email" className='form-control' id="Email" name="email" value={formData.email} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="Phone" className='form-label'>
                                                        <span style={{ color: '#e46e62' }}>Phone</span>
                                                        </label>
                                                        <input type='tel' className='form-control' id="Phone" name="Phone" value={formData.phone} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="LoansTaken" className='form-label'>
                                                        <span style={{ color: '#e46e62' }}>Loans Taken</span>
                                                        </label>
                                                        <input type="number" className='form-control' id="LoansTaken" name="LoansTaken" value={formData?.loansTaken?.length} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="KYCStatus" className='form-label'>
                                                        <span style={{ color: '#e46e62' }}>KYC Status</span>
                                                        </label>
                                                        <input type="text" className='form-control' id="KYCStatus" name="KYCStatus" value={formData.kycstatus} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="BusinessType" className='form-label'>
                                                        <span style={{ color: '#e46e62' }}>Buisness Type</span>
                                                        </label>
                                                        <select className='form-control'>
                                                            <option hidden>Select Buisness Type</option>
                                                            {buisnessTypeList?.map((item,indx) =>
                                                                <option selected={formData.buisness_type == item._id} key={indx}>{item.buisness_type_name}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                            <span style={{ color: '#e46e62' }}>KYC Details</span>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>Pan Number : {formData?.kyc?.panno}</span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/PanCard/${formData?.kyc?.panImage}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>Aadhar Number : {formData?.kyc?.adhaarno}</span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/Aadhar/${formData?.kyc?.adhaarImage}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>Bank Statement </span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/BankStatement/${formData?.kyc?.bankstatement}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>GST No. : {formData?.kyc?.GSTno}</span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/GST/${formData?.kyc?.GSTdoc}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>MSME </span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/MSME/${formData?.kyc?.MSME}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            </div>
  )
}

export default ApprovedKYC
