import React from "react";
import { BrowserRouter , Route, Routes } from "react-router-dom";

import Login from "../components/login/Login";
import VerificationDashboards from "../Dashboards/VerificationDashboards";
import LoanDisburstment from "../Dashboards/LoanDisburstment";
import LoanManagement from "../Dashboards/LoanManagement";
import LoanApproval from "../Dashboards/LoanApproval";
import DuedDashboard from "../Dashboards/DueDashboard";
import LoanApplications from "../Dashboards/LoanApplications";
import Testimonials from "../Dashboards/Testimonials";
import Documents from "../components/master/Documents";
import LoanType from "../components/master/LoanType";

const Myroutes = () => {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" Component={Login} />
                    {/* <Route exact path="/main" Component={Main} /> */}
                    <Route exact path="/LoanApplications" Component={LoanApplications} />
                    <Route exact path="/Verifications" Component={VerificationDashboards} />
                    <Route exact path="/LoanDisburstment" Component={LoanDisburstment} />
                    <Route exact path="/LoanManagement" Component={LoanManagement} />
                    <Route exact path="/LoanApproval" Component={LoanApproval} />
                    <Route exact path="/DuedDashboard" Component={DuedDashboard} />
                    <Route exact path="/Testimonials" Component={Testimonials} />
                    <Route exact path="/Documents" Component={Documents} />
                    <Route exact path="/LoanType" Component={LoanType} />
                    
                    {/* <Route exact path="/" component={Main} /> */}
                
                        {/* <Main /> */}
                    {/* </Route> */}
                    {/* <Route exact path="/" component={Login} /> */}
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default Myroutes
