import React from 'react'
import Menu from '../components/master/Menu'
import Header from '../components/master/Header'

const LoanApproval = () => {
  return (
      <div>
          <Header/>
      <Menu/>
    </div>
  )
}

export default LoanApproval
