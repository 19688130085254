import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Modal, Button } from 'react-bootstrap';
import Menu from "../components/master/Menu";
// import Header from "../components/master/Header";
export default function LoanApplications() {
    const navigate = useNavigate()
    const [users, setusersData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [visibleedit, setVisibleedit] = useState(false);
    const [visibladd, setVisibleAdd] = useState(false);
    const [formData,setFormData] = useState({})
    const [mystate,setMystate] = useState(0)
    const [buisnessTypeList, setBTypeList] = useState([])
    useEffect(() => {
        const token = localStorage.getItem('admintoken')
        if (token) {
            console.log(token, "token>>>>")
            GetusersData();
            getBusinessTypeList()
        } else {
            navigate("/")
        }
    }
        , []);
    const GetusersData = async () => {
        try {
            const requestOptions = {
              method: 'GET',
              headers: { 'Content-Type': 'application/json' },
            };
            const response = await fetch(`https://phapi.handsintechnology.in/api/Allloans?status=pending`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                console.log(res?.data,">>>>>>>>>>>>>>>> resdata")
              setusersData(res?.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const AddAdmin = async (e) => {
     
    }
    const sendToVerify =  (id,status) => {
        try {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            };

             fetch(`https://phapi.handsintechnology.in/api/updateLoanApplication?status=${status}&id=${id}`, requestOptions)
                .then((response) => response.json())
                 .then((res) => {
                     console.log(res)
                        if (res.status == 200) {
                            console.log(res?.data, ">>>>>>>>>>>>>>>> resdata")
                            GetusersData()
                        }
                 })
        } catch (error) {
            console.log(error)
        }
    }
    const ViewDetails = async (id) => {
        try {
            setVisibleedit(true)
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };

            const response = await fetch(`https://phapi.handsintechnology.in/api/getUserDetails?id=${id}`, requestOptions)
            const res = await response.json()
            if (res.status == 200) {
                console.log(res?.data, ">>>>>>>>>>>>>>>> user dataaa")
                setFormData(res?.data[0])
           
            }
        } catch (error) {
            console.log(error)
        }
    }
    const logout = () => {
        localStorage.clear('admintoken')
        navigate("/")
    }
    const getBusinessTypeList =async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            await fetch(`https://phapi.handsintechnology.in/api/businessTypeList`, requestOptions)
                .then((response) => response.json())
                .then((res) => {
                    if (res.status == 200) {
                        console.log(res.data, ">>>>>buisness type list data")
                        setBTypeList(res?.data)
                    }
                })

        } catch (error) {
            console.log(error)
        }
    }
  
    return (
        <div className="content-wrapper">
            <Menu/>
            {/* Content Header (Page header) */}
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0 text-dark">Loan Applications</h1>
                        </div>
                        {/* /.col */}
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <div className="items-right">
                                        <button
                                            onClick={() => logout()}
                                            className="btn btn-warning">
                                            <i className="fa fa-sign-out" aria-hidden="true" />Logout
                                        </button>
                                    </div>
                                </li>
                                {/* <li className="breadcrumb-item active">
                                    <Link to="/dashboard">
                                        <i className="fas fa-dashboard" /> Dashboard
                                    </Link>
                                </li> */}
                            </ol>
                           
                        </div>
                        {/* /.col */}
                        
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
                
            </div>
            <hr/>
            {/* /.content-header */}
            {/* Main content */}
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <table className="table table-bordered">
                            <thead className="thead-dark">
                                <tr className="table-info">
                                    <th> No.</th>
                                    <th>Full Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>KYC Status</th>
                                    <th>Monthly Turnover</th>
                                    <th>EMI's</th>
                                    <th>Loan Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users?.length > 0 ?
                                    <>

                                        {users?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.applicant[0].first_name} {item.applicant[0].last_name}</td>
                                                <td>{item.applicant[0].phone}</td>
                                                <td>{item.applicant[0].email}</td>
                                                <td>{item.applicant[0].kycstatus}</td>
                                                <td>{item.applicant[0]?.monthly_turnover}</td>
                                                <td>{item.totalEMIs}</td>
                                                <td>
                                                    {item.amtReq}
                                                    {/* <button className="btn btn-primary" onClick={() => { usersDetails(item._id); }}>Edit</button>

                                                    {' '}
                                                    <button className="btn btn-danger mx-1" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deleteItem(item._id) }}>Delete</button> */}
                                                </td>
                                                <td>
                                                    <button className="btn btn-primary" onClick={() => ViewDetails(item.applicant[0]?._id)}>View</button>
                                                    <button className="btn btn-success mx-1" onClick={() => { if (window.confirm('Confirm to Approve this Application')) sendToVerify(item._id, 'approved') }}>Approve</button>
                                                    <button className="btn btn-danger mx-1 my-1" onClick={() => { if (window.confirm('Confirm to Reject this Application')) sendToVerify(item._id,'rejected') }}>Reject</button>
                                                </td>
                                            </tr>
                                        ))}

                                    </>
                                    :
                                    <tr><td colSpan="17" style={{ textAlign: 'center' }}><strong>{"No Data Found"}</strong></td></tr>
                                }

                            </tbody>
                        </table>
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </div>
            <Modal size='lg' show={visibleedit} onHide={() => setVisibleedit(false)}>
                <Modal.Header >
                    <Modal.Title>Application Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="FirstName" className='form-label'>
                                                            <span style={{ color: '#e46e62' }}>First name</span>
                                                        </label>
                                                        <input type="text" className='form-control' id="FirstName" name="FirstName" value={formData.first_name} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        
                                                        <label htmlFor="LastName" className='form-label'><span style={{ color: '#e46e62' }}>Last Name</span></label>
                                                        <input type="text" className='form-control' id="LastName" name="LastName" value={formData.last_name}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        
                                                        <label htmlFor="Email" className='form-label'><span style={{ color: '#e46e62' }}>Email</span></label>
                                                        <input type="email" className='form-control' id="Email" name="email" value={formData.email} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        
                                                        <label htmlFor="Phone" className='form-label'><span style={{ color: '#e46e62' }}>Phone</span></label>
                                                        <input type='tel' className='form-control' id="Phone" name="Phone" value={formData.phone} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        
                                                        <label htmlFor="LoansTaken" className='form-label'><span style={{ color: '#e46e62' }}>Loans Taken</span></label>
                                                        <input type="number" className='form-control' id="LoansTaken" name="LoansTaken" value={formData?.loansTaken?.length} />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        
                                                        <label htmlFor="KYCStatus" className='form-label'><span style={{ color: '#e46e62' }}>KYC Status</span></label>
                                                        <input type="text" className='form-control' id="KYCStatus" name="KYCStatus" value={formData.kycstatus} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <label htmlFor="BusinessType" className='form-label'>
                                                            <span style={{ color: '#e46e62' }}>Buisness Type</span>
                                                        </label>
                                                        <select className='form-control'>
                                                            <option hidden>Select Buisness Type</option>
                                                            {buisnessTypeList?.map((item, indx) =>
                                                                <option selected={formData.buisness_type == item._id} key={indx}>{item.buisness_type_name}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <label className="form-label">
                                                <span style={{ color: '#e46e62' }}>KYC Details</span>
                                            </label>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>Pan Number : {formData?.kyc?.panno}</span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/PanCard/${formData?.kyc?.panImage}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>Aadhar Number : {formData?.kyc?.adhaarno}</span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/Aadhar/${formData?.kyc?.adhaarImage}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>Bank Statement </span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/BankStatement/${formData?.kyc?.bankstatement}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>GST No. : {formData?.kyc?.GSTno}</span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/GST/${formData?.kyc?.GSTdoc}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='mb-3'>
                                                        <span style={{ color: 'black' }}>MSME </span>
                                                        <label htmlFor="BusinessType" className='form-label'></label>
                                                        <div style={{ height: '250px', width: '250px' }}>
                                                            <img style={{ height: '100%', width: '100%' }} src={`https://phapi.handsintechnology.in/Documents/MSME/${formData?.kyc?.MSME}`} alt='image not found' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size='lg' show={visibladd} onHide={() => setVisibleAdd(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body">
                                        <form action="/" method="POST" onSubmit={AddAdmin}>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">First Name</label>
                                                        <input type="text" className="form-control" placeholder="First Name" name="first_name" autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Last Name</label>
                                                        <input type="text" className="form-control" placeholder="Last Name" name="last_name" autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Email</label>
                                                        <input type="text" className="form-control" placeholder="Email" name="email" autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Phone</label>
                                                        <input type="text" className="form-control" placeholder="Phone Number" name="phone" autoComplete="off" required />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Password</label>
                                                        <input type="text" className="form-control" placeholder="Enter Password" name="password" autoComplete="off" required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <button type="submit" className="btn btn-primary mt-2 submit_all" style={{ float: "right" }}>Add</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setVisibleedit(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
