import React from 'react';
import { createRoot } from 'react-dom/client'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
reportWebVitals()
export const API_URL = 'indiagram.handsintechnology.in/'
const hostname =
  window.location.hostname === 'localhost'
    ? window.location.hostname + ':3000'
    : window.location.hostname
export const forntendurl = window.location.protocol + '//' + hostname
